import { useEffect, useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import CommentsSection from '@/components/CommentsSection';
import axiosInstance from '@/utils/axiosInstance';
import { LoadingScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { convertNumsToFa } from '@/utils/numLangChanger'
import { CartContext } from '@/context/CartContext';
import { isDotIr } from '@/utils/isDotIr'
import Cookies from 'js-cookie'

import img0 from "@/assets/image/for-courses.png"
import lineDecorationIcon from "@/assets/svg/line-decoration1.svg"
import circlesIcon from "@/assets/svg/circles.svg"
import priceIcon from "@/assets/svg/price2.svg"
import arrowIcon from '@/assets/svg/Arrow - Left.svg'

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// Logger utility
const Logger = {
    step: (message: string) => {
        console.log(`✅ Step: ${message}`);
    },
    error: (message: string, error: any) => {
        console.error(`❌ Error: ${message}`, error);
    },
    info: (message: string) => {
        console.info(`ℹ️ Info: ${message}`);
    }
};

const decodeHtmlEntities = (str) => {
    Logger.step('Decoding HTML entities');
    try {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    } catch (error) {
        Logger.error('Failed to decode HTML entities', error);
        return str;
    }
}

const splitText = (text: string) => {
    Logger.step('Splitting text content');
    try {
        const decodedText = decodeHtmlEntities(text);
        return decodedText.split(/<v>/);
    } catch (error) {
        Logger.error('Failed to split text', error);
        return [text];
    }
};

const Courses = () => {
    const { cart, addToCart } = useContext(CartContext);
    const [nav_DropDown, setNav_DropDown] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [coursesData, setCoursesData] = useState(null);

    const close_Popup = e => {
        Logger.step('Handling popup close');
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) {
            setNav_DropDown(false);
        }
    };

    const priority1 = coursesData?.courses.find(course => course.priority === 1);
    const priorityLast = coursesData?.courses.find(course => course.priority === Math.max(...coursesData?.courses.map(course => course.priority || 0)));
    const middleCourses = coursesData?.courses.filter(course => course.priority !== 1 && course.priority !== priorityLast?.priority);

    console.log(priorityLast);

    const fetchData = async () => {
        Logger.step('Starting to fetch course data');
        try {
            Logger.step('Fetching course list');
            const response = await axiosInstance.get('/website/course_list');
            const courses = response.data;

            if (Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')) {
                Logger.step('User is logged in, fetching purchased courses');
                try {
                    const purchasedResponse = await axiosInstance.get('/order/purchased', {
                        headers: {
                            'Authorization': `Bearer ${Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')}`,
                        },
                    });

                    if (purchasedResponse.data.status === 1) {
                        Logger.step('Processing purchased courses data');
                        const purchasedOrders = purchasedResponse.data.purchased_orders;
                        const purchasedPackages = purchasedResponse.data.purchased_packages;

                        const updatedCourses = courses.courses.map(course => {
                            const isPurchasedInIr = purchasedOrders.ir.includes(course.id) || purchasedPackages.ir.includes(course.id)
                            const isPurchasedInCom = purchasedOrders.com.includes(course.id) || purchasedPackages.com.includes(course.id)
                            return { ...course, isPurchasedInIr, isPurchasedInCom };
                        });

                        setCoursesData({ ...courses, courses: updatedCourses });
                    }
                } catch (purchaseError) {
                    Logger.error('Failed to fetch purchased courses', purchaseError);
                    setError('Failed to fetch purchased courses data');
                }
            } else {
                Logger.info('User not logged in, setting basic course data');
                setCoursesData(courses);
            }
        } catch (error) {
            Logger.error('Failed to fetch course list', error);
            setError('Failed to fetch course data');
        }
    };

    const isLogin = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
    const [purchasedProducts, setPurchasedProducts] = useState(null);

    const checkIfPurchased = product => {
        if (!product) return false;
        return purchasedProducts?.find(i => i === product.id);
    };

    const fetchPurchasedData = async () => {
        Logger.step('Fetching purchased products data');
        try {
            const response = await axiosInstance.get('/order/purchased', {
                headers: {
                    'Authorization': `Bearer ${Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')}`,
                },
            });

            if (response.data.status === 1) {
                Logger.step('Processing purchased products');
                const purchasedOrders = response.data.purchased_orders;
                const purchasedPackages = response.data.purchased_packages;

                const purchasedInIr = [...purchasedOrders.ir, ...purchasedPackages.ir];
                const purchasedInCom = [...purchasedOrders.com, ...purchasedPackages.com];

                setPurchasedProducts(isDotIr ? [...purchasedInIr, ...purchasedInCom] : purchasedInCom);
            }
        } catch (error) {
            Logger.error('Failed to fetch purchased products', error);
            setError('Failed to fetch purchased products data');
        }
    };

    const handleAddToCart = (course) => {
        Logger.step(`Adding course ${course.id} to cart`);
        try {
            addToCart(course);
            Logger.info(`Successfully added course ${course.id} to cart`);
        } catch (error) {
            Logger.error('Failed to add course to cart', error);
        }
    };

    useEffect(() => {
        Logger.step('Converting numbers to Farsi');
        try {
            convertNumsToFa();
        } catch (error) {
            Logger.error('Failed to convert numbers to Farsi', error);
        }
    }, [coursesData]);

    useEffect(() => {
        Logger.step('Component mounted, initializing data');
        fetchData();
        if (isLogin) fetchPurchasedData();
    }, []);

    const theEl = useRef(null);
    const [desFromTop, setDesFromTop] = useState();

    const handleDistanceFromTop = () => {
        Logger.step('Calculating distance from top');
        const element = theEl.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const offsetTop = rect.top + scrollTop;
            setDesFromTop(offsetTop);
        }
    }

    useEffect(() => {
        handleDistanceFromTop();
    }, [coursesData]);

    window.onresize = () => handleDistanceFromTop();

    const tooltipRef = useRef(null);
    const tooltipRef2 = useRef(null);

    useEffect(() => {
        if (tooltipRef.current) {
            Logger.step('Initializing first tooltip');
            tippy(tooltipRef.current, {
                content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش دوره، VPN خود را خاموش و صفحه را رفرش کنید.",
            });
        }
    }, [priority1]);

    useEffect(() => {
        if (tooltipRef2.current) {
            Logger.step('Initializing second tooltip');
            tippy(tooltipRef2.current, {
                content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش دوره، VPN خود را خاموش و صفحه را رفرش کنید.",
            });
        }
    }, [priorityLast]);

    // Display error message if there is one
    if (error) {
        Logger.info(`Displaying error message: ${error}`);
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
                <h2 className="text-2xl font-bold text-red-600 mb-4">خطا در بارگذاری</h2>
                <p className="text-gray-700 mb-4 text-center">{error}</p>
                <button
                    onClick={() => {
                        Logger.step('Retrying data fetch');
                        setError(null);
                        fetchData();
                    }}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                >
                    تلاش مجدد
                </button>
            </div>
        );
    }

    // Rest of the component remains the same...
    return (
        <div className='bg-white text-[#212121]' onClick={close_Popup}>
            <LoadingScreen loading={!coursesData} />

            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <section className="relative pt-40 max-lg:pt-40 overflow-hidden">
                <div
                    style={{
                        transform: 'skew(0,4deg)',
                        '--des-from-top': `${desFromTop}px`
                    } as React.CSSProperties}
                    className='bg-box-color absolute inset-0 lg:mt-[550px] mb-[100px] max-lg:mb-[130px] bg-[#1F1F1F] rounded-[5vw] z-0'
                ></div>

                <div className='my-container relative'>
                    <img src={lineDecorationIcon} className='absolute -left-24 top-[500px]' />
                    <img src={lineDecorationIcon} className='absolute -right-24 bottom-40' />

                    <div className='text-center'>
                        <h3 className='text-[#212121]'>{coursesData?.category.list_title}</h3>
                        <p className='font-semibold text-[23px] mt-4'>قبل از خرید، حتما متن زیر مطالعه شود.</p>
                        <div className='lg:!px-32 flex items-center my-6 mt-3'>
                            <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                            <img src={circlesIcon} className='mt-[1px]' />
                            <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                        </div>
                        <p className='max-lg:hidden font-medium text-[19px] text-justify' style={{ textAlignLast: 'center' }}
                            dangerouslySetInnerHTML={{ __html: coursesData?.category.list_info }}
                        />
                        <p
                            className='lg:hidden font-medium text-[19px] text-justify'
                            dangerouslySetInnerHTML={{ __html: coursesData?.category.list_info }}
                        />
                    </div>

                    {priority1 && (
                        <div className="md:mt-14 max-md:mt-7 flex max-md:flex-col-reverse md:gap-8 max-md:gap-5 md:py-5 max-md:py-2 max-md:px-2 md:px-5 max-md:rounded-2xl md:rounded-3xl text-black" style={{ border: '1px solid #C4C4C4', background: 'linear-gradient(90deg, #fff, #E4E4E4)' }}>
                            <div className="flex flex-col gap-4 justify-between grow max-md:p-3 pt-0 max-md:-mt-2">
                                <div className='flex flex-col gap-4'>
                                    <h2 className='text-[28px] text-black text-center'>{priority1.title_fa}</h2>
                                    <div className='flex items-center -mt-2'>
                                        <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                                        <img src={circlesIcon} className='mt-[1px]' />
                                        <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                    <p
                                        className='font-medium text-[19px] text-justify'
                                        dangerouslySetInnerHTML={{ __html: splitText(priority1?.full_info)[0] }}
                                    />
                                </div>
                                <div className='flex flex-col gap-4'>
                                    <div className='flex items-center'>
                                        <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                                        <img src={circlesIcon} width={35} className='mt-[1px]' />
                                        <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                    <div className="flex justify-between flex-wrap">
                                        <div className="flex gap-[.4rem] items-center">
                                            <img src={priceIcon} />
                                            <b className='text-[#212121] text-[21px]'>اشتراک آنلاین:</b>
                                        </div>
                                        <div className='flex gap-1 mr-auto'>
                                            {priority1?.discount_price && priority1?.discount_price !== priority1?.price ?
                                                <>
                                                    <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                        {isDotIr ? (priority1?.price / 10).toLocaleString() : (priority1?.price || 0).toLocaleString()}
                                                    </p>
                                                    <div className={`rounded text-[21px] font-medium`}>
                                                        <b className={`text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                            {isDotIr ? (priority1?.discount_price / 10).toLocaleString() : (priority1?.discount_price || 0).loLocaleString()} {' '}
                                                        </b>
                                                        {isDotIr ? 'تومان' : '$'}
                                                    </div>
                                                </>
                                                :
                                                <p className={`text-[21px] font-medium`}>
                                                    <b className={`text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                        {isDotIr ? (priority1?.price / 10).toLocaleString() : (priority1?.price || 0).toLocaleString()} {' '}
                                                    </b>
                                                    {isDotIr ? 'تومان' : '$'}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap-reverse sm:gap-5 max-sm:gap-3 relative">
                                        {priority1.remain_capacity === 0 ?
                                            <button className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-white bg-[#888] pointer-events-none'>در حال اماده سازی.</button>
                                            : checkIfPurchased(priority1) ?
                                                <Link to={`/dashboard/products/courses/course/${priority1.id}`} className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-white bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition'>بخش نمایش دوره</Link>
                                                :
                                                <button onClick={() => addToCart(priority1)} disabled={cart.some(cartItem => cartItem.id === +priority1.id)} style={{ background: cart.some(cartItem => cartItem.id === +priority1.id) && 'var(--green-primary-active)' }} className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-white bg-[--green-primary] hover:bg-[--green-primary-hover] transition'>
                                                    {cart.some(cartItem => cartItem.id === +priority1.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                </button>
                                        }
                                        <Link to={`/course/${priority1.id}`} className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-black hover:bg-[#f8f8f8] transition' style={{ border: '1px solid #464646' }}>اطلاعات بیشتر</Link>

                                        {!isDotIr && priority1.isPurchasedInIr && !priority1.isPurchasedInCom &&
                                            <div ref={tooltipRef} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                    <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                </svg>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <img ref={theEl} src={priority1.image_media[0].src} className='w-[340px] max-md:w-full my-auto rounded-xl object-cover p-1 bg-white' style={{ border: '1px solid #e0e0e0' }} />
                        </div>
                    )}

                    <div className="md:mt-14 max-md:mt-5 flex gap-[24px] lg:gap-y-14 justify-center flex-wrap">
                        {middleCourses?.map((course, index) => {
                            const productPurchasedInIran = !isDotIr && course.isPurchasedInIr && !course.isPurchasedInCom;

                            if (productPurchasedInIran) {
                                tippy(`#tooltip-${course.id}`, {
                                    content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش دوره، VPN خود را خاموش و صفحه را رفرش کنید.",
                                });
                            }

                            return (
                                <div key={index} className="w-[calc(33.33%-16px)] max-lg:w-[calc(50%-12px)] max-md:w-full flex flex-col p-2 rounded-2xl text-black" style={{ border: '1px solid #C4C4C4', background: 'linear-gradient(160deg, #e3e3e3 40%, #fff 90%, #c2c2c2)' }}>
                                    <img src={course.image_media[0].src} className='w-full rounded-xl object-cover p-1 bg-white' alt="Course" />
                                    <div className="flex flex-col gap-1 justify-between grow px-3 py-2">
                                        <h2 className='text-[28px] text-black text-center mb-[-2px]'>{course.title_fa}</h2>
                                        <p
                                            className='font-semibold text-[19px] text-center'
                                            dangerouslySetInnerHTML={{ __html: splitText(course.full_info)[0] }}
                                        />
                                        <div className="flex flex-col gap-2">
                                            <div className="flex justify-between flex-wrap">
                                                <div className="flex gap-[.4rem] items-center">
                                                    <img src={priceIcon} alt="Price" />
                                                    <b className='text-[#212121] text-[21px]'>اشتراک آنلاین:</b>
                                                </div>
                                                <div className='flex gap-1 mr-auto'>
                                                    {course?.discount_price && course?.discount_price !== course?.price ?
                                                        <>
                                                            <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                                {isDotIr ? (course?.price / 10).toLocaleString() : (course?.price || 0).toLocaleString()}
                                                            </p>
                                                            <div className={`rounded text-[21px] font-medium`}>
                                                                <b className={`text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                    {isDotIr ? (course?.discount_price / 10).toLocaleString() : (course?.discount_price / 1).toLocaleString()} {' '}
                                                                </b>
                                                                {isDotIr ? 'تومان' : '$'}
                                                            </div>
                                                        </>
                                                        :
                                                        <p className={`text-[21px] font-medium`}>
                                                            <b className={`text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                {isDotIr ? (course?.price / 10).toLocaleString() : (course?.price || 0).toLocaleString()} {' '}
                                                            </b>
                                                            {isDotIr ? 'تومان' : '$'}
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap-reverse gap-[10px] max-sm:gap-3 mt-2 relative">
                                                {course.remain_capacity === 0 ?
                                                    <button className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-white bg-[#888] pointer-events-none'>در حال اماده سازی.</button>
                                                    : checkIfPurchased(course) ?
                                                        <Link to={`/dashboard/products/courses/course/${course.id}`} className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-white bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition'>بخش نمایش دوره</Link>
                                                        :
                                                        <button onClick={() => addToCart(course)} disabled={cart.some(cartItem => cartItem.id === +course.id)} style={{ background: cart.some(cartItem => cartItem.id === +course.id) && 'var(--green-primary-active)' }} className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-white bg-[--green-primary] hover:bg-[--green-primary-hover] transition'>
                                                            {cart.some(cartItem => cartItem.id === +course.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                        </button>
                                                }
                                                <Link to={`/course/${course.id}`} className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-black hover:bg-[#f8f8f8] transition' style={{ border: '1px solid #464646' }}>اطلاعات بیشتر</Link>

                                                {productPurchasedInIran &&
                                                    <div id={`tooltip-${course.id}`} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {middleCourses?.length % 3 === 1 &&
                            <div className='max-lg:hidden grow rounded-xl bg-white p-1 w-[calc(66.66%-16px)] relative' style={{ border: '1px solid #E0E0E0' }}>
                                <img src={img0} alt='درباره حسین ارا' className='object-cover h-full' />
                                <div className="absolute left-20 top-[30%] flex flex-col items-center">
                                    <h3 className='text-black text-[33px]'>حسین اُرا</h3>
                                    <p className='text-[#393939] font-semibold'>آشنایی با مدرس دوره‌ها</p>
                                    <Link to='/about-us' className='group grow rounded-xl mt-4 py-1 px-4 flex gap-2 items-center text-center font-semibold text-[20px] text-white bg-[--green-primary] hover:bg-[--green-primary-hover] transition'>
                                        ادامه مطلب
                                        <img src={arrowIcon} className='group-hover:scale-x-105 group-hover:translate-x-[-3px] transition' />
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>

                    {priorityLast && (
                        <div className="md:mt-14 max-md:mt-5 flex max-md:flex-col md:gap-8 max-md:gap-5 md:py-5 max-md:py-2 max-md:px-2 md:px-5 max-md:rounded-2xl md:rounded-3xl text-black" style={{ border: '1px solid #C4C4C4', background: 'linear-gradient(60deg, #E4E4E4, #fff)' }}>
                            <img src={priorityLast.image_media[0].src} className='w-[340px] max-md:w-full my-auto rounded-xl object-cover p-1 bg-white' style={{ border: '1px solid #e0e0e0' }} />
                            <div className="flex flex-col gap-4 justify-between grow max-md:p-3 pt-0 max-md:-mt-2">
                                <div className='flex flex-col gap-4'>
                                    <h2 className='text-[28px] text-black text-center'>{priorityLast.title_fa}</h2>
                                    <div className='flex items-center -mt-2'>
                                        <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                                        <img src={circlesIcon} className='mt-[1px]' />
                                        <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                    <p
                                        className='font-medium text-[19px] text-justify'
                                        dangerouslySetInnerHTML={{ __html: splitText(priorityLast?.full_info)[0] }}
                                    />
                                </div>
                                <div className='flex flex-col gap-4'>
                                    <div className='flex items-center'>
                                        <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                                        <img src={circlesIcon} width={35} className='mt-[1px]' />
                                        <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                    <div className="flex justify-between flex-wrap">
                                        <div className="flex gap-[.4rem] items-center">
                                            <img src={priceIcon} />
                                            <b className='text-[#212121] text-[21px]'>اشتراک آنلاین:</b>
                                        </div>
                                        <div className='flex gap-1 mr-auto'>
                                            {priorityLast?.discount_price && priorityLast?.discount_price !== priorityLast?.price ?
                                                <>
                                                    <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                        {isDotIr ? (priorityLast?.price / 10).toLocaleString() : (priorityLast?.price || 0).toLocaleString()}
                                                    </p>
                                                    <div className={`rounded text-[21px] font-medium`}>
                                                        <b className={`text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                            {isDotIr ? (priorityLast?.discount_price / 10).toLocaleString() : (priorityLast?.discount_price || 0).toLocaleString()} {' '}
                                                        </b>
                                                        {isDotIr ? 'تومان' : '$'}
                                                    </div>
                                                </>
                                                :
                                                <p className={`text-[21px] font-medium`}>
                                                    <b className={`text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                        {isDotIr ? (priorityLast?.price / 10).toLocaleString() : (priorityLast?.price || 0).toLocaleString()} {' '}
                                                    </b>
                                                    {isDotIr ? 'تومان' : '$'}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap-reverse sm:gap-5 max-sm:gap-3 mt-2 relative">
                                        {priorityLast.remain_capacity === 0 ?
                                            <button className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-white bg-[#888] pointer-events-none'>در حال اماده سازی.</button>
                                            : checkIfPurchased(priorityLast) ?
                                                <Link to={`/dashboard/products/courses/course/${priorityLast.id}`} className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-white bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition'>بخش نمایش دوره</Link>
                                                :
                                                <button onClick={() => addToCart(priorityLast)} disabled={cart.some(cartItem => cartItem.id === +priorityLast.id)} style={{ background: cart.some(cartItem => cartItem.id === +priorityLast.id) && 'var(--green-primary-active)' }} className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-white bg-[--green-primary] hover:bg-[--green-primary-hover] transition'>
                                                    {cart.some(cartItem => cartItem.id === +priorityLast.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                </button>
                                        }
                                        <Link to={`/course/${priorityLast.id}`} className='grow rounded-xl py-2 px-2 text-center font-semibold text-[19px] text-black hover:bg-[#f8f8f8] transition' style={{ border: '1px solid #464646' }}>اطلاعات بیشتر</Link>

                                        {!isDotIr && priorityLast.isPurchasedInIr && !priorityLast.isPurchasedInCom &&
                                            <div ref={tooltipRef2} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                    <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                </svg>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <CommentsSection fromCategory withExtraInfo id={+coursesData?.category.id} title='دیدگاه شرکت کنندگان دوره‌ها' />

            <Footer />
        </div>
    );
}

export default Courses;