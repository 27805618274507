import axios from 'axios';
import Cookies from 'js-cookie';
import { isDotIr } from '@/utils/isDotIr'

const axiosInstance = axios.create({
    baseURL: 'https://admin.innoghte.com/api'
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.data && error.response.data.message === "Unauthenticated.") {
            Cookies.remove(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
            window.location.href = '/auth';
            console.log('Unauthenticated');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;