import { FC, useState, useEffect } from 'react'
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { KTIcon } from '@/metronic-dashboard/helpers/components/KTIcon';
import { toAbsoluteUrl } from '@/metronic-dashboard/helpers/AssetHelpers';
import { useLayout } from '@/metronic-dashboard/helpers/layout/core/LayoutProvider';
import { HeaderToolbar } from './HeaderToolbar';

import logo from 'src/assets/svg/logo-black.svg'

interface HeaderWrapperProps {
  toggleDrawer: () => void;
}

export const HeaderWrapper: FC<HeaderWrapperProps> = ({ toggleDrawer }) => {
  const { config, classes, attributes } = useLayout();
  const { aside } = config;

  const [asideShow, setAsideshow] = useState(false)
  useEffect(() => {
    if (asideShow) {
      document.body.setAttribute('data-kt-aside-minimize', 'on')
    } else {
      document.body.removeAttribute('data-kt-aside-minimize')
    }
  }, [asideShow])

  return (
    <>
      <style>
        {`
          @keyframes shine {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }

          .animate-shine {
            animation: shine 2s infinite linear;
          }
        `}
      </style>
      <style>
        {`
          @keyframes shine {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }

          .animate-shine {
            animation: shine 2s infinite linear;
          }
        `}
      </style>
      <div
        id="kt_header"
        className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
        {...attributes.headerMenu}
      >
        {/* begin::Brand */}
        <div className="header-brand p-8 py-10">
          {/* begin::Logo */}
          <Link to='/' className='flex items-center gap-2 justify-center'>
            <img
              alt="Logo"
              width={100}
              src={logo}
              className="invert"
            />
          </Link>
          {/* end::Logo */}
          {aside.minimize && (
            <div
              id="kt_aside_toggle"
              className={`btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize  ${asideShow ? 'active' : ''}`}
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="aside-minimize"

              onClick={() => setAsideshow(!asideShow)}
            >
              <KTIcon iconName="exit-left" className="fs-1 me-n1 minimize-active text-[--bs-text-muted] scale-125" />
              <KTIcon iconName="entrance-left" className="fs-1 minimize-default text-[--bs-text-muted] scale-125" />
            </div>
          )}
          {/* begin::Aside toggle */}
          <div className="d-flex align-items-center d-lg-none me-n3 ms-1" title="Show aside menu">
            <div
              className="btn btn-icon relative overflow-hidden w-30px h-30px bg-orange-500"
              id="kt_aside_mobile_toggle"
              onClick={toggleDrawer}
            >
              <KTIcon
                iconName="abstract-14"
                className="fs-1 text-orange-500 scale-150 relative z-10"
              />
                  
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent animate-shine opacity-25" />
            </div>
            
          </div>
          {/* end::Aside toggle */}
        </div>
        {/* end::Brand */}
        <HeaderToolbar />
      </div>
    </>
  );
};

