import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const difference = +new Date(targetDate) - +new Date();

            if (difference > 0) {
                setTimeLeft({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                });
            } else {
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    const TimerUnit = ({ value, label }) => (
        <div className="flex flex-col items-center mx-2 sm:mx-2">
            <div className="bg-white/90 rounded-lg p-2 sm:p-3 min-w-[60px] sm:min-w-[80px]">
                <div className="text-2xl sm:text-2xl font-bold text-gray-950 text-center">
                    {String(value).padStart(2, '0')}
                </div>
            </div>
            <div className="text-sm sm:text-base mt-1 text-gray-950 font-medium">
                {label}
            </div>
        </div>
    );

    return (
        <div>
            <div className="flex justify-center backdrop-blur-sm rounded-xl mx-6">
                <TimerUnit value={timeLeft.days} label="روز" />
                <TimerUnit value={timeLeft.hours} label="ساعت" />
                <TimerUnit value={timeLeft.minutes} label="دقیقه" />
                <TimerUnit value={timeLeft.seconds} label="ثانیه" />
            </div>

            
        </div>

    );
};

export default CountdownTimer;