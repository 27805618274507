import React, { createContext, useState, useEffect } from 'react';

type CartItem = {
    id: number;
    title_fa: string;
    price: number;
    discount_price: number;
    discount_type: number;
    remain_capacity: number;
    image_src: string;
};

type CartContextType = {
    cart: CartItem[];
    addToCart: (item: any) => void;
    removeFromCart: (id: number) => void;
    clearCart: () => void;
};

const CartContext = createContext<CartContextType>({
    cart: [],
    addToCart: () => { },
    removeFromCart: () => { },
    clearCart: () => { }
});

const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [cart, setCart] = useState<CartItem[]>(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const addToCart = (item: any) => {
        const cartItem: CartItem = {
            id: item.id,
            title_fa: item.title_fa,
            price: item.price,
            discount_type: item.discount_type,
            discount_price: item.discount_price,
            remain_capacity: item.remain_capacity,
            image_src: (item.image_media && item.image_media.length > 0) ? item.image_media[0].src : '',
        };

        setCart([...cart, cartItem]);
    };

    const removeFromCart = (id: number) => {
        setCart(cart.filter(cartItem => cartItem.id !== id));
    };

    const clearCart = () => {
        setCart([]);
    };

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};

export { CartContext, CartProvider };