import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import CommentsSection from '@/components/CommentsSection';
import axiosInstance from '@/utils/axiosInstance';
import { LoadingScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { convertNumsToFa } from '@/utils/numLangChanger';
import { CartContext } from '@/context/CartContext';
import { isDotIr } from '@/utils/isDotIr';
import Cookies from 'js-cookie';

// Import SVG assets
import workshopIcon from '@/assets/svg/workshop.svg';
import chatIcon from '@/assets/svg/Chat 2.svg';
import docIcon from '@/assets/svg/doc.svg';
import arrowIcon from '@/assets/svg/Arrow - Left.svg';
import lineDecorationIcon from "@/assets/svg/line-decoration1.svg";
import circlesIcon from "@/assets/svg/circles.svg";
import starIcon from '@/assets/svg/Star 3.svg';
import excludeIcon from "@/assets/svg/Exclude2.svg";
import heartIcon from '@/assets/svg/Heart2.svg';
import timeCircle2Icon from "@/assets/svg/Time Circle2.svg";

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const Albums = () => {
    const [nav_DropDown, setNav_DropDown] = useState(false);
    const [errorLogs, setErrorLogs] = useState([]);
    const [albumsData, setAlbumsData] = useState(null);
    const [purchasedProducts, setPurchasedProducts] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [noComment, setNoComment] = useState(false);

    const { cart, addToCart } = useContext(CartContext);

    const logError = (error, source) => {
        const errorMessage = {
            timestamp: new Date().toISOString(),
            source: source,
            message: error.message,
            details: error.response?.data || error.stack
        };
        setErrorLogs(prev => [...prev, errorMessage]);
        console.error(`Error in ${source}:`, errorMessage);
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.get('/website/album_list');
            const albums = response.data;

            try {
                const authToken = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
                if (authToken) {
                    const purchasedResponse = await axiosInstance.get('/order/purchased', {
                        headers: { 'Authorization': `Bearer ${authToken}` },
                    });

                    if (purchasedResponse.data.status === 1) {
                        const { purchased_orders, purchased_packages } = purchasedResponse.data;

                        const updateCoursesWithPurchaseInfo = (courses) => {
                            return courses.map(album => ({
                                ...album,
                                isPurchasedInIr: purchased_orders.ir.includes(album.id) || purchased_packages.ir.includes(album.id),
                                isPurchasedInCom: purchased_orders.com.includes(album.id) || purchased_packages.com.includes(album.id),
                            }));
                        };

                        setAlbumsData({
                            ...albums,
                            non_package_courses: updateCoursesWithPurchaseInfo(albums.non_package_courses),
                            package_courses: updateCoursesWithPurchaseInfo(albums.package_courses)
                        });
                    }
                } else {
                    setAlbumsData(albums);
                }
            } catch (purchaseError) {
                logError(purchaseError, 'fetchPurchasedData');
                setAlbumsData(albums); // Still set albums data even if purchase check fails
            }
        } catch (error) {
            logError(error, 'fetchData');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPurchasedData = async () => {
        try {
            const authToken = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
            if (!authToken) return;

            const response = await axiosInstance.get('/order/purchased', {
                headers: { 'Authorization': `Bearer ${authToken}` },
            });

            if (response.data.status === 1) {
                const { purchased_orders, purchased_packages } = response.data;
                const purchasedInIr = [...purchased_orders.ir, ...purchased_packages.ir];
                const purchasedInCom = [...purchased_orders.com, ...purchased_packages.com];
                setPurchasedProducts(isDotIr ? [...purchasedInIr, ...purchasedInCom] : purchasedInCom);
            }
        } catch (error) {
            logError(error, 'fetchPurchasedData');
        }
    };

    const checkIfPurchased = product => {
        if (!product) return;
        return purchasedProducts?.find(i => i === product.id);
    };

    useEffect(() => {
        fetchData();
        const isLogin = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
        if (isLogin) fetchPurchasedData();
    }, []);
    const des1 = useRef(null)
    const des2 = useRef(null)
    const des3 = useRef(null)

    const scrollHandler = (destination) => {
        if (destination.current) {
            const { top } = destination.current.getBoundingClientRect();
            const offset = 80;
            window.scrollTo({
                top: window.scrollY + top - offset,
                behavior: 'smooth'
            });
        }
    }

    const theEl = useRef(null);
    const [desFromTop, setDesFromTop] = useState()

    const handleDistanceFromTop = () => {
        const element = theEl.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const offsetTop = rect.top + scrollTop;
            setDesFromTop(offsetTop)
        }
    }

    useEffect(() => {
        handleDistanceFromTop()
    }, [albumsData])

    window.onresize = () => handleDistanceFromTop()


    useEffect(() => {
        if (albumsData) {
            try {
                convertNumsToFa();
            } catch (error) {
                logError(error, 'convertNumsToFa');
            }
        }
    }, [albumsData]);

    // Rest of your component code remains the same, just remove any redirect logic
    // and keep all the rendering logic

    // Add an error log display section
    const ErrorLogs = () => (
        <div className="bg-red-100 p-4 rounded-lg mt-4">
            <h4 className="text-red-800 font-bold mb-2">Error Logs:</h4>
            {errorLogs.map((log, index) => (
                <div key={index} className="mb-2 p-2 bg-white rounded">
                    <p className="text-gray-600">{log.timestamp}</p>
                    <p className="font-bold">{log.source}</p>
                    <p className="text-red-600">{log.message}</p>
                    {log.details && (
                        <pre className="text-sm text-gray-700 mt-1">
                            {JSON.stringify(log.details, null, 2)}
                        </pre>
                    )}
                </div>
            ))}
        </div>
    );

    return (
        <div className='bg-white' onClick={() => {
            const dropdownElement = document.querySelector('.nav-dropdown');
            if (nav_DropDown && dropdownElement)
                setNav_DropDown(false);
        }}>
            <LoadingScreen loading={isLoading} />
            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            {/* Show error logs if any exist */}
            {errorLogs.length > 0 && <ErrorLogs />}

            {/* Rest of your JSX remains the same */}
            {/* Just remove any conditional rendering that would hide content on error */}

            {/* Your existing sections */}
            <section className="relative pt-40 max-lg:pt-32 overflow-hidden">
                <div
                    style={{
                        transform: 'skew(0,4deg)',
                        '--des-from-top': `${desFromTop}px`
                    } as React.CSSProperties}
                    className='bg-box-color absolute inset-0 lg:mt-[340px] mb-[100px] max-lg:mb-[120px] bg-[#152824] rounded-[5vw] z-0'
                ></div>

                <div className='my-container'>
                    <div className='relative flex lg:gap-16 max-lg:flex-col max-lg:mt-5 max-w-full'>
                        <div className='lg:hidden'>
                            <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                                <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-4'>
                                    <img src={workshopIcon} width={30} className='max-lg:hidden' />
                                    {albumsData?.category.title}
                                </h3>
                            </div>
                            <div className='text-[#212121] text-[21px] font-semibold max-lg:text-center mb-4'>
                                {albumsData?.category.list_title}
                            </div>
                        </div>

                        <div ref={theEl} className='mt-auto mx-auto max-w-full'>
                            <div className='rounded-xl p-1 bg-white w-[370px] h-[455px] max-w-full relative' style={{ border: '1px solid #E0E0E0' }}>
                                <img src={albumsData?.category.image} className='rounded h-full w-max object-cover' />
                            </div>
                        </div>
                        <div className='grow gap-14 flex flex-col justify-between max-lg:text-center'>
                            <div className='max-lg:hidden mt-2'>
                                <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                                    <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-4'>
                                        <img src={workshopIcon} width={30} className='max-lg:hidden' />
                                        {albumsData?.category.title}
                                    </h3>
                                </div>
                                <div className='text-[#212121] text-[21px] font-semibold  max-lg:text-[20px] max-lg:text-center mb-5'>
                                    {albumsData?.category.list_title}
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex max-lg:order-3 justify-between max-lg:justify-center flex-wrap items-center gap-3 mt-6'>
                                    <button onClick={() => scrollHandler(des1)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                        <img src={docIcon} className='max-lg:hidden' width={20} />
                                        <p className='font-semibold max-lg:font-medium max-lg:text-[18px]'>آلبوم‌های ارائه شده</p>
                                    </button>
                                    <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                    <button onClick={() => scrollHandler(des2)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                        <img src={docIcon} className='max-lg:hidden' width={20} />
                                        <p className='font-semibold max-lg:font-medium max-lg:text-[18px]'>بسته جامع آلبوم‌ها</p>
                                    </button>
                                    <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                    <button onClick={() => scrollHandler(des3)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                        <img src={chatIcon} className='max-lg:hidden' width={21} />
                                        <p className='font-semibold max-lg:font-medium max-lg:text-[18px]'>دیدگاه شرکت کنندگان</p>
                                    </button>
                                </div>

                                <div className='mt-6 flex flex-wrap gap-y-1 gap-x-10 justify-between max-lg:flex-col max-lg:items-center'>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-bold'>پیش نیاز:<span className='text-[#F1C31B] mr-2 text-[21px]'>ندارد</span></p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>قابلیت دانلود:<span className='text-[#F1C31B] mr-2 text-[21px]'>دارد</span></p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>نحوه دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>اشتراک آنلاین و دائمی</span></p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>تعداد دستگاه‌های مجاز:<span className='text-[#F1C31B] mr-2 text-[21px]'>دو دستگاه</span></p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>محدودیت دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {isDotIr ? 'جهت استفاده در کشور ایران' : 'ندارد'}
                                        </span></p>
                                    </div>
                                </div>

                                {/* <div className='flex gap-2 items-center rounded bg-[#0abbb51c] p-[.6rem] px-4 mt-5 max-lg:items-start max-lg:mx-auto'>
                                    <img src={excludeIcon} className='max-lg:mt-1' />
                                    <p className='text-[18px] font-medium text-[#0ABBB5] text-right'>آلبوم‌های موسیقی بی‌کلام، امکان دانلود و ذخیره کردن آهنگ‌ها را دارند.</p>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div ref={des1} className='relative lg:pt-20 max-lg:pt-14'>
                        <img src={lineDecorationIcon} className='absolute left-[-134px] -top-48' />
                        <img src={lineDecorationIcon} className='absolute right-[-134px] -bottom-96' />

                        <div className="max-lg:hidden flex items-center gap-5">
                            <h3>آلبوم‌های ارائه شده</h3>
                            <div className='h-[1px] grow bg-[#eee6]'></div>
                        </div>
                        <div className="lg:hidden text-center">
                            <h3>آلبوم‌های ارائه شده</h3>
                            <div className='lg:px-32 w-full flex items-center mt-5 mb-2'>
                                <div className='grow h-[1px] bg-[#bdbdbd]'></div>
                                <img src={circlesIcon} className='mt-[1px]' />
                                <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                            </div>
                        </div>

                        <div className="flex flex-wrap justify-center gap-x-6 lg:gap-y-10 max-lg:gap-y-6 mt-7">
                            {albumsData?.non_package_courses?.map((item, index) => {
                                const productPurchasedInIran = !isDotIr && item.isPurchasedInIr && !item.isPurchasedInCom;

                                if (productPurchasedInIran) {
                                    tippy(`#tooltip-${item.id}`, {
                                        content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش آلبوم، VPN خود را خاموش و صفحه را رفرش کنید.",
                                    });
                                }

                                return (
                                    <div key={index} className='w-[357px] max-sm:w-full max-w-full sm:h-[500px] bg-[#1F3833] rounded-3xl p-5 flex flex-col max-sm:gap-3 items-center justify-between' style={{ border: '1px solid #35413F' }}>
                                        <img src={item.image_media[0].src} className='object-cover rounded-full aspect-square w-full p-1 bg-white max-w-[357px] max-sm:mb-2' />
                                        <h3>{item.title_fa}</h3>
                                        <div className="flex justify-between w-full items-center flex-wrap gap-x-4">
                                            <div className="flex gap-1 items-center">
                                                <img src={heartIcon} />
                                                <div className=''>
                                                    <span className='font-semibold text-[19px]'>تعداد آهنگ:</span>{' '}
                                                    <span className='mr-1 font-normal text-[20px]'>{item.chapters_count} عدد</span>
                                                </div>
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <img src={timeCircle2Icon} />
                                                <div className=''>
                                                    <span className='font-semibold text-[19px]'>مدت زمان:</span>{' '}
                                                    <span className='mr-1 font-normal text-[20px]'>{item.duration} ساعت</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap-reverse items-center gap-2 w-full justify-between relative">
                                            {item.remain_capacity === 0 ?
                                                <button className='grow justify-center h-10 flex items-center px-2 rounded-xl bg-[#888] pointer-events-none font-semibold text-[20px]'>ظرفیت تکمیل است.</button>
                                                : checkIfPurchased(item) ?
                                                    <Link to={`/dashboard/products/albums/album/${item.id}`} className='grow justify-center text-white h-10 flex items-center px-2 rounded-xl transition bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-semibold text-[20px]'>بخش نمایش آلبوم</Link>
                                                    :
                                                    <button onClick={() => addToCart(item)} disabled={cart.some(cartItem => cartItem.id === +item.id)} style={{ background: cart.some(cartItem => cartItem.id === +item.id) && 'var(--green-primary-active)' }} className='grow justify-center h-10 flex items-center px-2 rounded-xl transition bg-[#67BD5C] hover:bg-[#5bac51] font-semibold text-[20px]'>
                                                        {cart.some(cartItem => cartItem.id === +item.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                    </button>
                                            }
                                            <Link to={`/album/${item.id}`} className='grow justify-center hover:bg-white/5 text-white transition font-semibold text-[20px] h-10 flex items-center px-2 rounded-xl' style={{ border: '1px solid #fff' }}>اطلاعات بیشتر</Link>

                                            {productPurchasedInIran &&
                                                <div id={`tooltip-${item.id}`} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                        <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                    </svg>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div ref={des2} className='relative lg:pt-20 max-lg:pt-14'>
                        <div className="max-lg:hidden flex items-center gap-5">
                            <h3>بسته جامع آلبوم‌ها</h3>
                            <div className='h-[1px] grow bg-[#eee6]'></div>
                        </div>
                        <div className="lg:hidden text-center">
                            <h3>بسته جامع آلبوم‌ها</h3>
                            <div className='lg:px-32 w-full flex items-center mt-5 mb-2'>
                                <div className='grow h-[1px] bg-[#bdbdbd]'></div>
                                <img src={circlesIcon} className='mt-[1px]' />
                                <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                            </div>
                        </div>

                        {albumsData?.package_courses?.map((item, i) => {
                            const productPurchasedInIran = !isDotIr && item.isPurchasedInIr && !item.isPurchasedInCom;

                            if (productPurchasedInIran) {
                                tippy(`#tooltip-${item.id}`, {
                                    content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش آلبوم، VPN خود را خاموش و صفحه را رفرش کنید.",
                                });
                            }

                            return (
                                <div key={i} className="flex max-lg:flex-col gap-[28px] bg-[#1F3833] rounded-3xl p-5 mt-7" style={{ border: '1px solid #35413F' }}>
                                    <img src={item.image_media[0].src} className='rounded-full object-cover p-1 bg-white w-[318px] max-sm:w-full max-w-[357px] max-sm:aspect-square sm:h-[318px] m-auto' />

                                    <div className="lg:w-[calc(100%-338px)] flex bg-[#395751] rounded-2xl py-7 max-md:px-4">
                                        <div className="flex gap-px bg-[#737373] max-md:flex-col">
                                            <div className="w-full flex flex-col gap-2 bg-[#395751] md:px-7 max-md:pb-7">
                                                <h3>{item.title_fa}</h3>
                                                <div className="flex flex-col gap-2 md:min-h-[240px]">
                                                    <div
                                                        className='text-justify font-medium text-[20px]'
                                                        dangerouslySetInnerHTML={{ __html: item.full_info }}
                                                    />
                                                    <Link to={`/album/${item.id}`} className="group text-white flex gap-1 items-center mb-[-4px]">
                                                        ادامه مطلب
                                                        <img src={arrowIcon} className='group-hover:scale-x-110 group-hover:translate-x-[-2px] transition' />
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="w-full flex flex-col gap-4 justify-end bg-[#395751] md:px-7 max-md:pt-7">
                                                <div className="flex flex-col gap-1">
                                                    <div className='flex gap-2 items-center'>
                                                        <img src={starIcon} width={20} />
                                                        <p className='font-semibold text-[20px]'>مدت زمان کل آلبوم:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                            {item.duration} ساعت (حدودی)
                                                        </span></p>
                                                    </div>
                                                    <div className='flex gap-2 items-center'>
                                                        <img src={starIcon} width={20} />
                                                        <p className='font-semibold text-[20px]'>نحوه دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                            {item.access_type}
                                                        </span></p>
                                                    </div>
                                                    <div className='flex gap-2 items-center'>
                                                        <img src={starIcon} width={20} />
                                                        <p className='font-semibold text-[20px]'>تعداد آلبوم:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                            {item.included_courses_count}
                                                        </span></p>
                                                    </div>
                                                    <div className='flex gap-2 items-center'>
                                                        <img src={starIcon} width={20} />
                                                        <p className='font-semibold text-[20px]'>قابلیت دانلود:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                            {item.downloadable === 0 ? 'ندارد' : 'دارد'}
                                                        </span></p>
                                                    </div>
                                                </div>
                                                <div className="max-md:hidden flex flex-col gap-2 w-full">
                                                    <Link to={`/album/${item.id}`} className='grow justify-center hover:bg-white/5 text-white transition font-medium text-[20px] h-10 flex items-center px-5 rounded-xl' style={{ border: '1px solid #fff' }}>اطلاعات بیشتر</Link>
                                                    {item.remain_capacity === 0 ?
                                                        <button className='grow max-sm:w-full justify-center h-10 flex items-center px-5 rounded-xl bg-[#888] pointer-events-none font-semibold text-[20px]'>ظرفیت تکمیل شده است.</button>
                                                        : checkIfPurchased(item) ?
                                                            <Link to={`/dashboard/products/albums/album/${item.id}`} className='grow justify-center text-white h-10 flex items-center px-5 rounded-xl transition bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-medium text-[20px]'>بخش نمایش آلبوم</Link>
                                                            :
                                                            <button onClick={() => addToCart(item)} disabled={cart.some(cartItem => cartItem.id === +item.id)} style={{ background: cart.some(cartItem => cartItem.id === +item.id) && 'var(--green-primary-active)' }} className='grow justify-center h-10 flex items-center px-5 rounded-xl transition bg-[#67BD5C] hover:bg-[#5bac51] font-medium text-[20px] relative'>
                                                                <>
                                                                    {cart.some(cartItem => cartItem.id === +item.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}

                                                                    {productPurchasedInIran &&
                                                                        <div id={`tooltip-${item.id}`} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                                                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                                            </svg>
                                                                        </div>
                                                                    }
                                                                </>
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:hidden flex-wrap-reverse flex gap-3 w-full -mt-3">
                                        {item.remain_capacity === 0 ?
                                            <button className='grow max-sm:w-full justify-center h-10 flex items-center px-2 rounded-xl bg-[#888] pointer-events-none font-semibold text-[20px]'>ظرفیت تکمیل شده است.</button>
                                            : checkIfPurchased(item) ?
                                                <Link to={`/dashboard/products/albums/album/${item.id}`} className='grow justify-center h-10 flex items-center px-2 rounded-xl text-white transition bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-medium text-[20px]'>بخش نمایش آلبوم</Link>
                                                :
                                                <button onClick={() => addToCart(item)} disabled={cart.some(cartItem => cartItem.id === +item.id)} style={{ background: cart.some(cartItem => cartItem.id === +item.id) && 'var(--green-primary-active)' }} className='grow justify-center h-10 flex items-center px-2 rounded-xl transition bg-[#67BD5C] hover:bg-[#5bac51] font-medium text-[20px]'>
                                                    {cart.some(cartItem => cartItem.id === +item.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                </button>
                                        }
                                        <Link to={`/album/${item.id}`} className='grow justify-center hover:bg-white/5 text-white transition font-medium text-[20px] h-10 flex items-center px-2 rounded-xl' style={{ border: '1px solid #fff' }}>اطلاعات بیشتر</Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <CommentsSection
                fromCategory
                withExtraInfo
                id={albumsData?.category?.id || 0}
                title='دیدگاه مخاطبین آلبوم‌ها'
                onNoComments={() => setNoComment(true)}
            />

            <Footer />
        </div>
    );
};

export default Albums;