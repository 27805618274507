import { useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import axiosInstance from '@/utils/axiosInstance';
import { CartContext } from '@/context/CartContext';
import { convertNumsToFa } from '@/utils/numLangChanger'
import Cookies from 'js-cookie'
import { isDotIr } from '@/utils/isDotIr'
import Alert from '@/components/Alert';

import Exclude1 from '@/assets/svg/Exclude-green.svg'
import Exclude2 from '@/assets/svg/Exclude-red.svg'
import Close from '@/assets/svg/Close3.svg'
import Plus from '@/assets/svg/Plus.svg'
import Gift from '@/assets/svg/Gift.svg'
import Bank1 from '@/assets/svg/Bank Logo.svg'
import Bank2Gray from '@/assets/svg/Bank Logo-1-gray.svg'
import Bank3 from '@/assets/svg/Bank Logo-2.svg'
import Bank4 from '@/assets/svg/Bank4.svg'
import Bank5 from '@/assets/svg/Bank5gray.svg'
import Bank6 from '@/assets/svg/Bank6.svg'

import ShoppingCartLoginModal from '@/metronic-dashboard/pages/auth/components/ShoppingCartLoginModal';

const DiscountSection = ({ setFinalPrice, setPriceWithoutDiscount, setDiscount_code }) => {
    const { cart } = useContext(CartContext);
    const [discount2_code, setDiscount2_code] = useState('');
    const [loadingDiscount, setLoadingDiscount] = useState(false);
    const [discountMessage, setDiscountMessage] = useState(null);
    const [discountStatus, setDiscountStatus] = useState(null);




    const handleCheckDiscount = async () => {
        try {
            setLoadingDiscount(true);
            setDiscountMessage(null);

            const course_ids = cart.map(item => item.id);

            const response = await axiosInstance.post('/order/check_discount_code', {
                course_ids,
                discount_code: discount2_code || undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')}`,
                },
            });

            if (response.data.status === 1) {
                setDiscountStatus(1);
                setDiscountMessage('کد تخفیف اعمال شد.');
                setFinalPrice(response.data.final_price);
                setPriceWithoutDiscount(response.data.total_price)
            } else {
                setDiscountStatus(0);
                setDiscountMessage('کد تخفیف نامعتبر و یا تاریخ گذشته است.');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setDiscountStatus(0);
                setDiscountMessage('کد تخفیف نامعتبر و یا تاریخ گذشته است.');
            } else {
                setDiscountStatus(0);
                setDiscountMessage('مشکلی در دریافت اطلاعات پیش آمد. لطفا بعدا دوباره تلاش کنید.');
            }
        } finally {
            setLoadingDiscount(false);
        }
    };

    useEffect(() => {
        setDiscount_code(discount2_code)
    }, [discount2_code])

    useEffect(() => {
        setDiscount2_code('')
        setDiscountMessage('')
        setDiscountStatus('')
    }, [cart])

    return (
        <div>
            <span className='font-semibold text-[20px] mb-2 block'>اگر کد تخفیف دارید لطفا در زیر بنویسید.</span>
            <div className="flex bg-[#eee] px-5 rounded-xl relative">
                <img src={Gift} />
                <input
                    type="text"
                    value={discount2_code}
                    onChange={e => setDiscount2_code(e.target.value)}
                    placeholder='Agnai13679@'
                    className='grow bg-transparent p-4'
                />
                <img
                    src={loadingDiscount ? null : Plus}
                    onClick={handleCheckDiscount}
                    className={`absolute md:left-4 max-md:left-3 top-1/2 -translate-y-1/2 cursor-pointer ${loadingDiscount ? 'hidden' : ''}`}
                />
                {loadingDiscount && <span className="spinner-border spinner-border-sm align-middle ms-2 absolute left-5 top-[23px]"></span>}
            </div>
            {discountMessage && (
                <span className={`-mb-5 mt-1 block font-semibold text-[18px] ${discountStatus === 1 ? 'text-[#12D18E]' : 'text-[#F75555]'} right-0`}>
                    {discountMessage}
                </span>
            )}
        </div>
    );
};


interface OrderRequestData {
    course_ids: number[];
    discount_code?: string;
    payment_method?: string;
    first_name?: string;
    last_name?: string;
    card_number?: string;
    type?: string;
    expiry_month?: string;
    expiry_year?: string;
    cvv?: string;
}


const validateCardNumber = (cardType, number) => {
    const cleanNumber = number.replace(/\s/g, '');
    const cardPatterns = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        amex: /^3[47][0-9]{13}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
        dinersclub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
    };

    return cardPatterns[cardType]?.test(cleanNumber);
};


const ShoppingCart = () => {

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [nav_DropDown, setNav_DropDown] = useState(false)
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }


    const [paypalProcessing, setPaypalProcessing] = useState(false);
    const [paypalOrderId, setPaypalOrderId] = useState(null);

    const [checked, setChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isLogin = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com') ? true : false
    const [loading, setLoading] = useState(false)

    const { cart, removeFromCart } = useContext(CartContext)
    const [discount_code, setDiscount_code] = useState(null)

    const [finalPrice, setFinalPrice] = useState(cart.reduce((total, item) => total + item.discount_price, 0));
    const [priceWithoutDiscount, setPriceWithoutDiscount] = useState(null)

    const [paymentType, setPaymentType] = useState('paypal')

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardType, setCardType] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const [cvv, setCvv] = useState('');

    const handleOrderSubmit = async () => {
        if (cart.length === 0) {
            setErrorMessage('سبد خرید شما خالی می‌باشد.')
            return;
        }

        if (!isLogin) {
            setIsLoginModalOpen(true);
            return;
        }

        if (!checked) {
            setErrorMessage('لطفاً قوانین و مقررات را بپذیرید.');
            return;
        }

        if (paymentType === 'credit_card') {
            // Validate all required fields
            if (!firstName || !lastName || !cardNumber || !cardType || !expiryMonth || !expiryYear || !cvv) {
                setErrorMessage('لطفاً تمامی فیلدهای کارت اعتباری را پر کنید.');
                return;
            }

            // Validate first name and last name
            if (firstName.length < 2 || lastName.length < 2) {
                setErrorMessage('نام و نام خانوادگی باید حداقل 2 حرف داشته باشند.');
                return;
            }

            // Clean card number and validate based on type
            const cleanCardNumber = cardNumber.replace(/\s/g, '');
            if (!validateCardNumber(cardType, cleanCardNumber)) {
                setErrorMessage('شماره کارت وارد شده با نوع کارت انتخابی مطابقت ندارد.');
                return;
            }

            // Validate expiry month
            const monthNum = parseInt(expiryMonth);
            if (isNaN(monthNum) || monthNum < 1 || monthNum > 12) {
                setErrorMessage('ماه انقضا باید عددی بین 1 تا 12 باشد.');
                return;
            }

            // Validate expiry year
            const currentYear = new Date().getFullYear();
            const yearNum = parseInt(expiryYear);
            if (expiryYear.length !== 4 || isNaN(yearNum) || yearNum < currentYear) {
                setErrorMessage('سال انقضا باید 4 رقمی و بزرگتر یا مساوی سال جاری باشد.');
                return;
            }

            // Check if card is expired
            const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11
            if (yearNum === currentYear && monthNum < currentMonth) {
                setErrorMessage('کارت منقضی شده است.');
                return;
            }

            // Validate CVV length based on card type
            const cvvLength = cvv.length;
            if (cardType === 'amex') {
                if (cvvLength !== 4) {
                    setErrorMessage('برای کارت‌های American Express، کد CVV باید 4 رقمی باشد.');
                    return;
                }
            } else if (cvvLength !== 3) {
                setErrorMessage('کد CVV باید 3 رقمی باشد.');
                return;
            }
        }

        try {
            setLoading(true);
            const course_ids = cart.map(item => item.id);

            const requestData = {
                course_ids,
                discount_code: discount_code || undefined,
                payment_method: paymentType,
            };

            if (paymentType === 'credit_card') {
                Object.assign(requestData, {
                    first_name: firstName,
                    last_name: lastName,
                    card_number: cardNumber.replace(/\s/g, ''),
                    type: cardType,
                    expiry_month: expiryMonth,
                    expiry_year: expiryYear,
                    cvv: cvv
                });
            }


            const response = await axiosInstance.post(
                isDotIr ? '/order/create' : '/order/paypal/create-payment',
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')}`,
                    },
                }
            );

            if (response.data.status === 1 || response.data.status === 2) {
                const { data } = response.data;
            }

            // Handle zero-price orders (100% discount)
            if (response.data.data?.final_price === 0 || response.data.final_price === 0 || response.data.status === 2) {
                navigate('/payment/result', {
                    state: {
                        response: response.data,
                        products: cart
                    }
                });
                return;
            }



            // Store PayPal order ID
            setPaypalOrderId(response.data.paypal_order_id);

            const { order_id, payment_id, paypal_order_id, url } = response.data;

            // Store order details in localStorage for after approval
            localStorage.setItem('pending_order', JSON.stringify({
                order_id,
                payment_id,
                paypal_order_id,
                products: cart
            }));

            // Step 2: Redirect to PayPal for approval
            if (url) {
                window.location.href = url;
            } else {
                throw new Error('PayPal redirect URL not found');
            }

        } catch (error) {
            if (error.response?.status === 422) {
                setErrorMessage('لطفا از صحت اطلاعات کارت اعتباری اطمینان حاصل کرده و دوباره تلاش فرمایید.');
            } else if (error.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('متاسفانه مشکلی پیش آمد. لطفا بعدا دوباره تلاش کنید.');
            }
        } finally {
            setLoading(false);
        }

    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const PayerID = urlParams.get('PayerID');

        if (token && PayerID) {
            const pendingOrder = localStorage.getItem('pending_order');

            if (pendingOrder) {
                const orderDetails = JSON.parse(pendingOrder);

                // Step 3: Capture the order after approval
                axiosInstance.get(`/paypal/execute-payment?token=${token}`, {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('innoghteh_access_token_com')}`,
                    },
                })
                    .then(response => {
                        if (response.data.status === 1) {
                            navigate('/payment/result', {
                                state: {
                                    response: response.data,
                                    products: orderDetails.products
                                }
                            });
                        } else {
                            throw new Error(response.data.message || 'Payment capture failed');
                        }
                    })
                    .catch(error => {
                        handlePaymentError(error);
                    })
                    .finally(() => {
                        localStorage.removeItem('pending_order');
                    });
            }
        }
    }, []);



    const handlePaymentError = (error) => {
        if (error.response?.status === 422) {
            setErrorMessage('لطفا از صحت اطلاعات کارت اعتباری اطمینان حاصل کرده و دوباره تلاش فرمایید.');
        } else if (error.response?.data?.message) {
            setErrorMessage(error.response.data.message);
        } else {
            setErrorMessage('متاسفانه مشکلی پیش آمد. لطفا بعدا دوباره تلاش کنید.');
        }
        console.error('Payment Error:', error);
    };

    // Format credit card number with spaces
    const formatCardNumber = (value) => {
        const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        const matches = v.match(/\d{4,16}/g);
        const match = (matches && matches[0]) || '';
        const parts = [];

        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }

        if (parts.length) {
            return parts.join(' ');
        } else {
            return value;
        }
    };

    // Handle card number input
    const handleCardNumberInput = (e) => {
        let value = e.target.value;
        value = formatCardNumber(value);
        setCardNumber(value);
    };



    const handleAlertClose = () => setErrorMessage('')

    useEffect(() => {
        convertNumsToFa()
    }, [cart, finalPrice])

    useEffect(() => {
        setFinalPrice(cart.reduce((total, item) => total + item.discount_price, 0))
        setPriceWithoutDiscount(null)
        setDiscount_code(null)
    }, [cart])


    const handleNumberInput = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        e.target.value = value;
    };


    return (
        <div className='bg-white text-[#212121]' onClick={close_Popup}>
            {errorMessage && <Alert onClose={handleAlertClose} text={errorMessage} danger title='خطا' />}

            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <div className='my-container flex justify-center pt-40 max-lg:pt-32 lg:mb-8 max-lg:mb-14'>
                <div className='flex flex-col w-full max-w-full gap-3'>
                    <div className='w-full rounded-2xl p-5 relative overflow-hidden' style={{ border: '1px solid #ddd' }}>
                        <h3 className='text-[#212121] text-[27px] mb-7'>سفارش شما</h3>

                        <div className="flex justify-between">
                            <span className='font-semibold text-[20px]'>محصول</span>
                            <span className='font-semibold text-[20px]'>جمع کل</span>
                        </div>

                        <div className="flex flex-col gap-px border-y border-[#ddd] bg-[#ddd] my-5">
                            {cart.map((item, i) => (
                                <div key={i} className='bg-white flex flex-wrap gap-2 justify-between items-center py-3 first:pt-2 last:pb-2'>
                                    <div className='grow flex gap-4 items-center'>
                                        <img onClick={() => removeFromCart(item.id)} src={Close} className='mr-2 cursor-pointer' />
                                        <div className='w-[72px] h-[72px] rounded !border-2 border-[#eee] p-[3px]'>
                                            <img src={item.image_src} className='w-full h-full object-cover rounded-[5px]' />
                                        </div>
                                        <div className="flex max-lg:flex-col lg:gap-4 lg:items-center">
                                            <span className='font-semibold text-[20px]'>
                                                {item.title_fa}
                                            </span>
                                            <div className='max-lg:hidden w-px h-[20px] bg-[#aaa]' />
                                            <span className='font-semibold text-[20px]'>تعداد: 1</span>
                                        </div>
                                    </div>
                                    <span className='font-semibold text-[20px] mr-auto'>
                                        {item.discount_type > 1 ? (
                                            <div className='flex gap-1 items-center'>
                                                <span className='line-through'>{isDotIr ? (item.price / 10).toLocaleString() : item.price}</span>
                                                <p>
                                                    <span>{isDotIr ? (item.discount_price / 10).toLocaleString() : '$' + item.discount_price}</span>
                                                    {isDotIr && <span> تومان</span>}
                                                </p>
                                            </div>
                                        ) : (
                                            <>{isDotIr ? (item.price / 10).toLocaleString() + ' تومان' : '$' + item.price}</>
                                        )}
                                    </span>
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-between">
                            <span className='font-semibold text-[20px]'>قیمت نهایی</span>
                            <div className="flex gap-1 items-center">
                                <span className='font-semibold text-[20px] line-through'>
                                    {priceWithoutDiscount && (priceWithoutDiscount / 10)?.toLocaleString() || null}
                                </span>
                                <span className='font-semibold text-[20px]'>
                                    {isDotIr ? (finalPrice / 10).toLocaleString() + ' تومان' : '$' + finalPrice}
                                </span>
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-2 gap-10 mt-5">
                            <div>
                                <DiscountSection setFinalPrice={setFinalPrice} setPriceWithoutDiscount={setPriceWithoutDiscount} setDiscount_code={setDiscount_code} />

                                <div className="max-lg:hidden flex gap-2 items-start mt-8">
                                    <div
                                        className={`w-2.5 h-2.5 rounded-full outline-offset-2 mt-2 ml-0.5 ${checked ? 'bg-[#ed7d3e]' : ''}`}
                                        style={{ outline: '2px solid #ed7d3e' }}
                                        onClick={() => {
                                            setChecked(!checked);
                                            if (!checked) setErrorMessage('');
                                        }}
                                    />
                                    <p onClick={() => {
                                        setChecked(!checked);
                                        if (!checked) setErrorMessage('');
                                    }} className='text-[21px] font-semibold'>
                                        من <Link to='/terms' className='text-[21px] font-semibold !text-[#ED7D3E]'> قوانین ارائه خدمت </Link>
                                        وب‌سایت را خوانده‌ام و آن را می‌پذیرم.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <span className='font-semibold text-[24px] mb-2 -mt-2 block'>روش پرداخت</span>
                                {isDotIr ?
                                    <div className="flex gap-3 flex-wrap justify-center max-w-full mx-auto mb-3">
                                        <div className='grow flex items-center justify-center !border-2 border-[#999] p-2 rounded-lg cursor-pointer'>
                                            <img src={Bank3} />
                                        </div>
                                        <div className='grow flex items-center justify-center !border-2 border-[#eee] p-2 rounded-lg grayscale bg-[#fafafa]'>
                                            <img src={Bank2Gray} />
                                        </div>
                                        <div className='grow flex items-center justify-center !border-2 border-[#eee] p-2 rounded-lg grayscale bg-[#fafafa]'>
                                            <img src={Bank1} />
                                        </div>
                                    </div>
                                    :
                                    <div className="flex gap-3 flex-wrap justify-center max-w-full mx-auto mb-3">
                                        <div
                                            onClick={() => setPaymentType('paypal')}
                                            className={`grow flex items-center justify-center !border-2 ${paymentType === 'paypal' ? 'border-[#999]' : 'border-[#eee]'} p-3 rounded-lg cursor-pointer`}
                                        >
                                            <img src={Bank4} />
                                        </div>

                                        <div
                                            onClick={() => setPaymentType('credit_card')}
                                            className={`grow flex items-center justify-center !border-2 ${paymentType === 'credit_card' ? 'border-[#999]' : 'border-[#eee]'}  p-3 rounded-lg cursor-pointer `}
                                        // className='grow flex items-center justify-center !border-2 border-[#eee] p-3 rounded-lg grayscale bg-[#fafafa]'
                                        >
                                            <img src={Bank6} />
                                        </div>

                                        <div className='grow flex items-center justify-center !border-2 border-[#eee] p-3 rounded-lg grayscale bg-[#fafafa]'>
                                            <img src={Bank5} />
                                        </div>
                                    </div>
                                }

                                {paymentType === 'credit_card' && (
                                    <div className="grid lg:grid-cols-2 gap-y-4 gap-x-5 my-5">
                                        <div className="flex flex-col">
                                            <label htmlFor="name" className="font-semibold mb-1">نام*</label>
                                            <input
                                                type="text"
                                                id="name"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                className="bg-[#eee] h-12 px-4 rounded-lg text-[#212121] ltr"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="lastname" className="font-semibold mb-1">نام خانوادگی*</label>
                                            <input
                                                type="text"
                                                id="lastname"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                className="bg-[#eee] h-12 px-4 rounded-lg text-[#212121] ltr"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="card_type" className="font-semibold mb-1">نوع کارت*</label>
                                            <div className="bg-[#eee] h-12 px-4 rounded-lg">
                                                <select
                                                    id="card_type"
                                                    value={cardType}
                                                    onChange={(e) => setCardType(e.target.value)}
                                                    className="bg-transparent w-full h-full text-[#212121]"
                                                >
                                                    <option value="">انتخاب کنید...</option>
                                                    <option className="ltr text-[20px] font-medium" value="visa">Visa</option>
                                                    <option className="ltr text-[20px] font-medium" value="mastercard">Mastercard</option>
                                                    <option className="ltr text-[20px] font-medium" value="amex">Amex</option>
                                                    <option className="ltr text-[20px] font-medium" value="discover">Discover</option>
                                                    <option className="ltr text-[20px] font-medium" value="jcb">JCB</option>
                                                    <option className="ltr text-[20px] font-medium" value="dinersclub">Diners Club</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="cardnum" className="font-semibold mb-1">شماره کارت*</label>
                                            <input
                                                type="text"
                                                id="cardnum"
                                                value={cardNumber}
                                                onChange={(e) => setCardNumber(e.target.value)}
                                                onInput={handleNumberInput}
                                                maxLength={19}
                                                className="bg-[#eee] h-12 px-4 rounded-lg ltr text-[#212121]"
                                            />
                                        </div>
                                        <div className="grid gap-4 lg:col-span-2 lg:grid-cols-2 max-lg:grid-cols-3">
                                            <div className="flex flex-col max-lg:col-span-2">
                                                <label htmlFor="carddate" className="font-semibold mb-1">تاریخ انقضا*</label>
                                                <div className="flex gap-4">
                                                    <input
                                                        type="text"
                                                        id="carddate"
                                                        value={expiryMonth}
                                                        onChange={(e) => setExpiryMonth(e.target.value)}
                                                        onInput={handleNumberInput}
                                                        placeholder="MM"
                                                        maxLength={2}
                                                        className="bg-[#eee] h-12 text-center rounded-lg w-[calc(50%-8px)] text-[#212121]"
                                                    />
                                                    <input
                                                        type="text"
                                                        value={expiryYear}
                                                        onChange={(e) => setExpiryYear(e.target.value)}
                                                        onInput={handleNumberInput}
                                                        placeholder="YYYY"
                                                        minLength={4}
                                                        maxLength={4}
                                                        className="bg-[#eee] h-12 text-center rounded-lg w-[calc(50%-8px)] text-[#212121]"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-col">
                                                <label htmlFor="cvv" className="font-semibold mb-1">CVV*</label>
                                                <input
                                                    type="text"
                                                    id="cvv"
                                                    value={cvv}
                                                    onChange={(e) => setCvv(e.target.value)}
                                                    onInput={handleNumberInput}
                                                    maxLength={4}
                                                    className="bg-[#eee] h-12 text-center rounded-lg text-[#212121]"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="lg:hidden flex gap-2 items-start my-7">
                                    <div
                                        className={`w-2.5 h-2.5 rounded-full outline-offset-2 mt-2 ml-0.5 ${checked ? 'bg-[#ed7d3e]' : ''}`}
                                        style={{ outline: '2px solid #ed7d3e' }}
                                        onClick={() => setChecked(!checked)}
                                    />
                                    <p onClick={() => setChecked(!checked)} className='text-[20px] font-semibold'>
                                        من <Link to='/terms' className='text-[20px] font-semibold !text-[#ED7D3E]'> قوانین ارائه خدمت </Link>
                                        وب‌سایت را خوانده‌ام و آن را می‌پذیرم.</p>
                                </div>

                                <button
                                    onClick={handleOrderSubmit}
                                    disabled={loading}
                                    className='bg-[--orange-primary] hover:bg-[--orange-primary-hover] text-white w-full p-2 rounded-xl text-[22px] font-semibold'
                                >
                                    {loading ? 'لطفا کمی صبر کنید...' : 'ثبت سفارش'}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="w-full max-w-[600px] flex items-center bg-[#e7f8f8] gap-2 rounded p-2 mt-3">
                        <img src={Exclude1} />
                        <p className='text-[#0ABBB5] text-[18px] font-medium'>لطفا در نظر داشته باشید که دوره‌های مجموعه “این نقطه” در قالب اشتراک آنلاین ارائه می‌شود.</p>
                    </div>
                    {isDotIr &&
                        <div className="w-full max-w-[600px] flex items-center bg-[#ffe0de] gap-2 rounded p-2">
                            <img src={Exclude2} />
                            <p className='text-[#F75555] text-[18px] font-medium'>بعد از خرید، اشتراک شما تنها در جهت استفاده در کشور ایران فعال خواهد بود.</p>
                        </div>
                    }
                </div>
            </div>

            <ShoppingCartLoginModal
                isOpen={isLoginModalOpen}
                onClose={() => setIsLoginModalOpen(false)}
            />

            <Footer />
        </div>
    )
}


export default ShoppingCart;